@use '@glise/styles/globals' as *;
.FeaturesWrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .FeaturesCardWrapper {
    .FeaturesTitles {
      //display: flex;
      position: relative;

      .FeatureTitle {
        //flex: 1;
        text-align: center;
        padding: 1.5rem;
        cursor: pointer;
        transition: 0.3s ease;
        z-index: 1;

        &:hover {
          color: $darkThemeBasePurple;
        }

        &.Active {
          color: #ffffff;
        }
      }

      .ActiveIndicator {
        position: absolute;
        bottom: 0;
        left: var(--indicator-left);
        width: var(--indicator-width);
        height: 100%; // Adjust the thickness of the indicator
        border-radius: var(--Corner-Extra-large, 28px) var(--Corner-Extra-large, 28px) var(--Corner-None, 0px) var(--Corner-None, 0px);
        border-top: 1px solid #404040;
        border-right: 1px solid #404040;
        border-left: 1px solid #404040;
        background: var(--Dark-Backgrounds-bg-subtle, #18181a);
        transition: left 0.3s ease;
        z-index: 0;
      }
    }

    .SelectedFeatureWrapper {
      border-radius: 28px;
      border: 1px solid #404040;
      background: #18181a;
      padding: 2rem 4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-height: 600px;
      margin-top: -1px;
      transition: 0.3s ease;
      gap: 1rem;

      &.FirstActive {
        border-top-left-radius: 0;
      }

      &.LastActive {
        border-top-right-radius: 0;
      }

      > * {
        flex: 1;
      }

      .Content {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .Title {
          font-size: 40px;

          p {
            margin-bottom: 0;
          }

          .Main {
            @include colorfulTextBig;

            & {
              font-size: 40px;
              line-height: initial;
              font-weight: 500;
            }
          }
        }

        .Description {
          font-weight: 350;
          color: $darkThemeBaseWhiteDarkest;
          font-size: 20px;
        }
      }

      .SelectedFeaturePreview {
        display: flex;
        align-items: flex-end;
        justify-content: end;

        img {
          width: auto;
          //height: 100%;
        }
      }
    }

    @include md {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .FeatureTitle {
        border-radius: 6px;
        border: 1px solid rgba(255, 255, 255, 0);
        background: $darkThemeBaseDarkDarkest;
        padding: 0.5rem !important;
        height: fit-content;
        width: fit-content;

        &.Active {
          background: $darkThemeBasePurple;
        }
      }

      .ActiveIndicator {
        display: none;
      }

      .SelectedFeatureWrapper {
        padding: 0;
        flex-direction: column;
        gap: 2rem;
        max-height: fit-content;
        background: transparent;
        border-color: transparent;

        .SelectedFeaturePreview {
          width: 100%;

          img {
            width: 100%;
            height: auto;
          }
        }

        .Content {
          align-items: center;
          text-align: center;
        }
      }
    }
  }
}
