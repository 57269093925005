@use '@glise/styles/globals' as *;
.OverviewCard {
  border-radius: 8px;
  border: 1px solid $darkThemeBaseDark;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  flex: 1;
  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    height: 52%;
    width: 56%;
    opacity: 0;
    border-radius: 374.736px;
    background: #504dff;
    filter: blur(89.2229003906px);
    z-index: 0;
    transform: rotate(45deg);
    transition: 0.25s ease-in-out;
  }

  &.top-left:after {
    top: -18%;
    left: -12%;
  }

  &.top-right:after {
    top: -2%;
    right: 12%;
  }

  &.top:after {
    top: -50%;
    right: 12%;
  }

  &.bottom-right:after {
    top: 88%;
    right: 12%;
  }

  &:after {
    top: -20%;
    right: 21%;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }

  .ImageWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    //padding-top: 1rem;
    position: relative;
    margin-top: 0.5rem;
    justify-content: center;
    transition: 0.5s all;

    img {
      max-width: 100%;
      width: 100%;
      height: auto;
      max-height: 260px;
      z-index: 1;
    }
  }

  .ContentWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    z-index: 1;

    .Title {
      color: $darkThemeBaseWhiteDarker;
      font-size: 13px;
    }

    .Description {
      color: $darkThemeBaseWhiteDarkest;
      font-size: 13px;
    }
  }
}
