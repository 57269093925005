@use '@glise/styles/globals' as *;
.DropdownWrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid $darkThemeBaseDarkDarker;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4);
  gap: 1.5rem;
  transition: 0.5s ease-in-out;

  .Dropdown {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
    color: #fff;

    font-size: 2rem;
    line-height: 2.5rem;
    gap: 1rem;
    cursor: pointer;

    .DropdownIconWrapper {
    }
  }

  .DropdownItem {
    padding: 0 2rem 1.5rem;
    color: $darkThemeBaseWhiteDarkest;

    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: -0.01rem;
    //text-transform: capitalize;
    display: none;
    transition: 0.5s ease-in-out;
  }

  &.Open {
    border-radius: 0.5rem;
    border: 1px solid $darkThemeBasePurple;
    background: #0d0d0f;
    box-shadow: 0px 0px 0px 0.1px rgba(107, 104, 255, 0.8),
    0px 0px 0px 1px #171717;

    .Dropdown {
      padding-bottom: 0;

      svg {
        color: $darkThemeBasePurple;
      }
    }

    .DropdownItem {
      animation: slide-in 0.3s ease-in-out forwards;
      display: block;
    }
  }
}
