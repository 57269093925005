@use '@glise/styles/globals' as *;
.TextContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
  width: 100%;
  gap: 1rem;

  .Tagline {
    color: $darkThemeBasePurple;
    text-align: center;

    font-size: 0.875rem;
    line-height: 1.25rem;
    text-transform: uppercase;
  }

  .Title {
    color: $darkThemeBaseWhiteDarker;
    text-align: center;

    font-weight: bold;
    @include colorfulTextBig;

    & {
      font-size: 4rem;
      letter-spacing: -0.03rem;
    }

    @include md {
      font-size: 3rem;
    }
  }

  .Subtitle {
    color: $darkThemeBaseWhiteDarkest;
    text-align: center;

    font-size: 1.05rem;
    font-weight: 300;
    line-height: 1.5rem;
    max-width: 42rem;
    margin-bottom: 0;
  }

  &.side {
    align-items: flex-start;
    width: auto;

    .Title,
    .Subtitle {
      text-align: start;
    }
  }
}

@media screen and (min-width: $screen-md) {
  .TextContainer {
    .Tagline {
    }

    .Title {
      //margin-bottom: 1.5rem;

      @include md {
      }
    }

    .Subtitle {
    }
  }
}
