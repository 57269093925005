@use '@glise/styles/globals' as *;
.ProductOverviewContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2.5rem;

  .ProductOverviewWrapper {
  }

  .ProductOverviewSliderContainer {
    display: none;
    margin-top: -2.5rem;
    @include lg {
      display: block;
    }
  }
}
