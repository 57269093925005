@use '@glise/styles/globals' as *;
.TooltipContent {
  border-radius: $primary-border-radius !important;
  border: 1px solid $darkThemeBaseDark !important;
  background-color: $darkThemeBaseBlack !important;
  box-shadow: $tooltip-box-shadow !important;
  padding: $tooltip-padding !important;
  font-size: 0.75rem !important;
  color: $darkThemeBaseWhiteDarkest !important;

  &.sm {
    font-size: 10px !important;
    padding: 0.25rem 0.5rem !important;
    border-radius: 10px !important;
  }
}

[data-theme='light'] {
}
