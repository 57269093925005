@use '@glise/styles/globals' as *;
.PillsGroup {
  background-color: $darkThemeBaseBlackDarker;
  display: flex;
  border-radius: 2rem;
  gap: 0.5rem;
  //margin-bottom: 1.5rem;
  padding: 0.25rem;
  width: fit-content;

  &.purple-light-active {
    background-color: $darkThemeBaseBlackLight;
  }

  &.FullWidth {
    width: 100%;

    > * {
      flex: 1;
    }
  }
}
