@use '@glise/styles/globals' as *;
button.PillButton {
  border-radius: 5rem;
  text-align: center;
  text-transform: initial;
  padding-right: 1.5rem;
  padding-left: 1.5rem;

  &.md {
    //padding: 0.5rem 1rem;
  }

  &.sm {
    font-size: 13px;
  }

  &.Selected {
    border-radius: 6249.9375rem;
  }

  &:focus {
    border-radius: 5rem;
  }
}

[data-theme='light'] {
  button.PillButton {
    color: $lightThemeBaseGrayDarkest;
    border: 1px solid transparent;

    &:hover {
      color: $lightThemeBaseDarkDarkest;
    }

    &.Selected {
      background: $lightThemeBaseWhiteLight;
      box-shadow: 0px 2px 4px 0px rgba(3, 7, 18, 0.04),
      0px 1px 2px -1px rgba(3, 7, 18, 0.08),
      0px 0px 0px 1px rgba(3, 7, 18, 0.08);
    }

    &.transparent {
      background: transparent;
      box-shadow: none;
    }
  }
}

[data-theme='dark'] {
  button.PillButton {
    color: $darkThemeBaseWhiteDarkest;
    border: 1px solid transparent;
    //background-color: $darkThemeBaseBlack;

    &.bg-hover {
      background-color: transparent;

      &:hover {
        background-color: $darkThemeBaseBlack;
      }
    }

    &:hover {
      color: $darkThemeBaseWhiteDarker;
      background-color: $darkThemeBaseBlack;
    }

    &.Selected {
      background-color: $darkThemeBaseBlack;
      border: 1px solid #2e3035;
      color: $darkThemeBaseWhite;

      &.border {
        border: 1px solid $darkThemeBaseDarkDarker;
      }

      &.bg-hover {
        background-color: $darkThemeBaseBlack;
      }
    }

    &.transparent {
      background: transparent;
      box-shadow: none;
    }

    &.purple-light-active {
      background-color: transparent;

      &.Selected {
        background: $purpleBlackGradient;
        color: $darkThemeBaseWhiteDarker;

        &:hover {
          background: $darkThemeBaseBlueLight;
        }
      }

      &:hover {
        background-color: $darkThemeBaseBlack;
      }
    }

    &.purple-active {
      background-color: transparent;

      &.Selected {
        background: $purpleBlackGradient;
        color: $darkThemeBaseWhiteDarker;

        &:hover {
          background: $darkThemeBaseBlueLight;
        }
      }

      &:hover {
        background-color: $darkThemeBaseBlackLight;
      }
    }
  }
}
