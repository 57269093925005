@use '@glise/styles/globals' as *;
.ConnectToolsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 3rem;

  .IntegrationsImageWrapper,
  img {
    border-radius: 16px;
  }
}

@media screen and (max-width: $screen-xl) {
  .ConnectToolsContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .IntegrationsImageWrapper {
      order: 2;
      width: 100%;
      height: auto;
    }
  }
}
