@use '@glise/styles/globals' as *;
.HeroContentWrapper {
  z-index: 1;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
}

.HeroTextContainer {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;

  .BigText {
    text-align: center;
    font-weight: 600;
    margin-bottom: 0;
    @include colorfulTextBig;

    & {
      font-size: 4rem;
    }

    @include md {
      font-size: 3rem;
    }
  }

  .SmallText {
    color: $darkThemeBaseWhiteDarker;
    text-align: center;
    max-width: 35rem;
    margin-bottom: 0;

    @include colorfulTextSmall;
  }

  .Launch {
    display: flex;
    align-content: center;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;

    .Title {
      color: $darkThemeBaseWhiteDarker;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
    }

    .SubscribeInputWrapper {
      display: flex;
      align-content: stretch;

      .SubscribeInputContainer {
        flex: 1;

        .MessageIcon {
          left: 1rem;
          color: $darkThemeBaseWhiteDarkest;
        }

        .SubscribeInput {
          padding-left: 2.5rem;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          height: 100%;
        }
      }

      .SubscribeButton {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .Button {
    margin-top: 0.5rem;
    display: flex;
    padding: 0.625rem 0.75rem !important;
    height: auto;
    width: auto;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;

    text-transform: none;

    font-size: 0.875rem;
    line-height: 1.25rem;

    svg {
      path {
        stroke: $darkThemeBaseWhiteLightest;
      }
    }

    &:hover {
      background: $darkThemeBaseBlueLight;
    }
  }
}

.DashboardImage {
  z-index: 4;
  margin-top: 6rem;

  img {
    width: 100%;
    height: auto;
  }
}
