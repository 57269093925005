@use '@glise/styles/globals' as *;
.Border {
  padding: 0.5px 1px 1px;
  display: flex;
  border-radius: 16px;
  border: 1px solid $darkThemeBaseDarkDarker;
  width: 100%;

  &.Purple {
    border: 1px solid #807eff;
    background: linear-gradient(
                    180deg,
                    rgba(120, 118, 255, 0.32) 0%,
                    rgba(116, 113, 255, 0) 100%
    );
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.4);
    border-radius: 16px;

    .PricingPlanContainer {
      background: $darkThemeBaseBlack;
    }
  }

  .PricingPlanContainer {
    border-radius: 16px;
    width: 100%;
    height: 100%;
    padding: 2rem;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;

    &.blue {
      position: relative;
      overflow: hidden;
      border-radius: 16px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        border-radius: 16px;
        background: linear-gradient(
                        180deg,
                        rgba(120, 118, 255, 0.32) 0%,
                        rgba(116, 113, 255, 0) 100%
        );
      }

      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.4);
    }

    .PricingPlanInfo {
      color: $darkThemeBaseWhiteLightest;
      text-align: start;

      font-size: 2rem;
      line-height: 2.5rem;
      letter-spacing: -0.02rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      z-index: 3;

      .PlanName {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .PricingDescription {
          display: flex;
          gap: 0.5rem;
          color: $darkThemeBaseWhiteDarkest;

          font-size: 1rem;
          font-weight: 400;
          line-height: 1.25rem;
        }
      }

      .PricingPlanPrice {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        height: 2rem;
        color: $darkThemeBaseWhiteLightest;

        line-height: 2rem;
        letter-spacing: -0.015rem;
        font-size: 21px;
        margin-bottom: 2rem;

        &.StartNowPrice {
          margin-bottom: 0;
        }

        .Discount {
          border-radius: 0.5rem;
          background: $darkThemeBasePurple;
          padding: 0.25rem;
          color: $darkThemeBaseWhiteLightest;
          text-align: center;

          font-size: 18px;
          line-height: 1.75rem;
          width: fit-content;
        }
      }

      .ButtonWrapper {
        height: 39px;
        margin-bottom: 2rem;

        button {
          width: 100%;
        }
      }
    }

    .PricingPlanButton {
      width: 100%;
      padding: 1.2rem !important;
      color: $darkThemeBaseWhiteLightest;

      line-height: 1.25rem;
      z-index: 3;
    }

    .PricingPlanAdvantages {
      border-top: 1px solid $darkThemeBaseDarkLight;
      padding-top: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      color: $darkThemeBaseWhiteLightest;

      font-weight: 400;
      line-height: 1.25rem;
      padding-bottom: 1rem;
      z-index: 5;

      div {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      .Advantage {
        svg {
          color: $darkThemeBasePurple;
        }
      }
    }
  }
}

[data-theme='light'] {
}
