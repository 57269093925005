@use '@glise/styles/globals' as *;
.ChromeExtension {
  display: flex;
  border-radius: 28px;
  border: 1px solid #404040;
  background: #18181a;
  padding: 4rem;
  gap: 1rem;
  align-items: center;

  @include md {
    flex-direction: column;
    gap: 2rem;
    .Preview {
      img {
        width: 100%;
        height: auto;
        //transform: scale(1.5);
        margin-top: 2rem;
      }
    }
  }

  .Content {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    .ActionButtonWrapper {
      width: 300px;

      .ActionButton {
        width: 100%;
      }
    }
  }

  .Preview {
    img {
    }
  }
}
