@use '@glise/styles/globals' as *;
.FaqContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2.5rem;

  @include lg {
    padding: 1rem;
  }

  .FaqWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}
