@use '@glise/styles/globals' as *;
.PricingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 1.5rem;

  //@include lg {
  //  padding: 1rem;
  //}

  .PricingPlansWrapper {
    display: flex;
    gap: 1.5rem;
    width: 100%;

    @include lg {
      flex-direction: column;
      padding: 0;
    }
  }
}
